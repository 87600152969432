export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'regionCode',
    label: 'field.region',
    hideToggle: true,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    hideToggle: true,
  },
  {
    key: 'outletName',
    label: 'field.outlet',
    localization: true,
    subKey: 'outletCode',
    hideToggle: true,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'question',
    label: 'field.question',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'answer',
    label: 'field.answer',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
    type: 'datetime',
    hideToggle: true,
  },
];

export default [
  {
    key: 'regionId',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'agentId',
    label: 'field.agent',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'agent',
    selectionKey: 'id',
    selectionLabel: 'agentCode',
  },
  {
    key: 'outletId',
    label: 'field.outlet',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'outlet',
    selectionKey: 'id',
    selectionLabel: 'label',
    localization: true,
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'surveyId',
    label: 'field.survey',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'survey',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'surveySectionId',
    label: 'field.section',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'surveySection',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'surveyQuestionId',
    label: 'field.question',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'surveyQuestion',
    selectionKey: 'id',
    selectionLabel: 'question',
    localization: true,
    lg: 6,
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
]
